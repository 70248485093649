import { makeStyles } from '@material-ui/core';
import React from 'react';
import HomeHeroSection from './layouts/HomeHeroSection';
import PricingSection from './layouts/PricingSection';
import WhyChooseUsSection from './layouts/WhyChooseUsSection';

const useStyles = makeStyles(theme => ({
    root: {

    },
}))

const HomeScreen = () => {
    const classes = useStyles();
    return (
        <main className={classes.root}>
            <HomeHeroSection />
            <WhyChooseUsSection />
            {/* <PricingSection /> */}
        </main>
    )
}

export default HomeScreen
