import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import DiscordIcon from '../../assets/brand_logos/discord.svg';
import InstagramIcon from '../../assets/brand_logos/insta.svg';
import LinkedinIcon from '../../assets/brand_logos/linkedin.svg';
import TwitterIcon from '../../assets/brand_logos/twitter.svg';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0, 0, 5, 0),
        backgroundColor: theme.palette.primary.main,
        // minHeight: '70vh',
        overflow: 'hidden',
    },
    curvesArea: {
        position: 'relative',
        '& img': {
            width: '100%',
            position: 'absolute',
            top: 0,

            '&.curve1': {
                opacity: 0.2,
                transform: 'translateY(-8%) rotateZ(5.5deg) scale(1.05)',
            },
            '&.curve2': {
                opacity: 0.15,
                transform: 'translateY(-13%) rotateZ(3deg) scale(1.15)',
            },
            [theme.breakpoints.down('xs')]: {
                '&.curve1': {
                    transform: 'translateY(-30%) rotateZ(5.5deg) scale(1.05)',
                },
                '&.curve2': {
                    transform: 'translateY(-25%) rotateZ(1deg) scale(1.25)',
                }
            },
        }
    },
    topNegativeSpace: {
        height: theme.spacing(40),

        [theme.breakpoints.down('md')]: {
            height: theme.spacing(25),
        },
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(20),
        },
        [theme.breakpoints.down('xs')]: {
            height: theme.spacing(10),
        }
    },
    footerContainer: {
        padding: theme.spacing(0, 10, 0, 10),
        '& .MuiTypography-body1': {
            color: theme.palette.common.white
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 3, 0, 3),
        }
    },
    socialLinks: {
        textAlign: 'right',
        '& img': {
            marginLeft: theme.spacing(2),
            cursor: 'pointer'
        },

        [theme.breakpoints.down('sm')]: {
            '& img': {
                width: '2.5rem',
                height: 'auto',
                marginLeft: theme.spacing(1)
            },
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            paddingBottom: theme.spacing(4)
        }
    },
    footerColTitle: {
        fontWeight: 600,
        margin: theme.spacing(8, 0, 4, 0)
    },
    pageLinks: {
        marginBottom: theme.spacing(4),
        '& .MuiTypography-body1': {
            margin: theme.spacing(1.5, 0, 1.5, 0),
            display: 'block',
            textDecoration: 'none',

            '&:hover': {
                textDecoration: 'underline'
            }
        },
        [theme.breakpoints.down('xs')]: {
            margin: 0
        }
    },
    policyLinks: {
        textAlign: 'right',
        '& .MuiTypography-body1': {
            textDecoration: 'none',
            marginRight: theme.spacing(2),
            '&:hover': {
                textDecoration: 'underline'
            },
            [theme.breakpoints.down('xs')]: {
                display: 'block',
                marginTop: theme.spacing(1)
            }
        },

        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
        }
    }
}))

const Footer = () => {
    const classes = useStyles();

    return (
        <footer className={classes.root}>
            <div className={classes.curvesArea}>
                {/* <img className='curve1' src={WhiteInvertedCurve} alt='inverted curve' /> */}
                {/* <img className='curve2' src={WhiteInvertedCurve} alt='inverted curve' /> */}
            </div>
            {/* <div className={classes.topNegativeSpace}></div> */}
            <Grid container className={classes.footerContainer}>
                <Grid item xs={12} sm={6}>
                    <Typography className={classes.footerColTitle}>Instaconnect</Typography>
                    <div className={classes.pageLinks}>
                        <Typography component={Link} to={'#'}>About Us</Typography>
                        <Typography component={Link} to={'#'}>Contact & Support</Typography>
                        {/* <Typography component={Link} to={'#'}>FAQs</Typography> */}
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={classes.socialLinks}>
                        <Typography className={classes.footerColTitle}>Follow Our Socials</Typography>
                        <Link to={'#'}>
                            <img src={TwitterIcon} alt='twitter icon' />
                        </Link>
                        <Link to={'#'}>
                            <img src={InstagramIcon} alt='instagram icon' />
                        </Link>
                        <Link to={'#'}>
                            <img src={LinkedinIcon} alt='linkedin icon' />
                        </Link>
                        <Link to={'#'}>
                            <img src={DiscordIcon} alt='discord icon' />
                        </Link>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography>© {(new Date()).getFullYear()} Instaconnect&nbsp;&nbsp;•&nbsp;&nbsp;All Rights Reserved</Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.policyLinks}>
                    <Typography component={Link} to={'#'}>License</Typography>
                    <Typography component={Link} to={'#'}>Privacy policy</Typography>
                    {/* <Typography component={Link} to={'#'}>Cookie Policy</Typography> */}

                </Grid>
            </Grid>
        </footer>
    )
}

export default Footer
