import { AppBar, Box, Button, makeStyles, Toolbar, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Logo from '../../assets/logo.svg'
import { allNavbarLinks, linkType } from '../../utils/constants/navbarConst'
import theme from '../../utils/theme'
import AppDrawer from './AppDrawer'
import useWindowSize from './hooks/useWindowSize'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0, 10, 0, 10),
        boxShadow: 'none',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: theme.spacing(0, 3, 0, 3),
        }
    },
    toolbar: {
        padding: 0,
        display: 'flex',
        justifyContent: 'space-between',
    },
    navLinks: {
        '& a': {
            margin: theme.spacing(0, 4, 0, 4),
            fontWeight: 600,
            textDecoration: 'none',

            '&:hover': {
                textDecoration: 'underline'
            }
        }
    }
}))

const OnlyLargeScreen = () => {
    const classes = useStyles();
    return (
        <>
            <img src={Logo} alt='logo' />
            <Box className={classes.navLinks}>
                {
                    allNavbarLinks.filter(navLink => navLink.linkType === linkType.PUBLIC).map(navLink =>
                        <Typography key={navLink.url} component={Link} to={navLink.url} color='textPrimary'>
                            {navLink.label}
                        </Typography>
                    )
                }
            </Box>
            <Link component={Button} underline='none' color="inherit">Login</Link>
        </>
    )
}

const AllSmallDevices = () => {
    return (
        <>
            <img src={Logo} alt='logo' />
            <AppDrawer />
        </>
    )
}

const AppNavbar = () => {
    const classes = useStyles();
    const { width } = useWindowSize()
    return (
        <AppBar className={classes.root} color='transparent' position='absolute'>
            <Toolbar className={classes.toolbar}>
                {
                    (width > theme.breakpoints.values.md) ? <OnlyLargeScreen /> : <AllSmallDevices />
                }
            </Toolbar>
        </AppBar>
    )
}

export default AppNavbar
