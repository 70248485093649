import { Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useState } from 'react';
import HalfDots from '../../assets/half_dots.svg';
import { ReactComponent as PricingTick } from '../../assets/icons/pricing_tick.svg';
import Star from '../../assets/icons/star.svg';
import WhiteInvertedCurve from '../../assets/white_inverted_curve.svg';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(15, 0, 0, 0),
        backgroundColor: theme.palette.background.paper,

        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(35, 0, 0, 0),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(10, 0, 0, 0),
        },
    },
    heroText: {
        fontWeight: 900,
        textAlign: 'center',
        fontSize: 72,
        letterSpacing: -3.5,
        [theme.breakpoints.down('md')]: {
            fontSize: 48,
        },
    },
    subText: {
        color: theme.palette.grey[700],
        marginTop: theme.spacing(2),
        lineHeight: '200%',
        textAlign: 'center'
    },
    pricingCardList: {
        padding: theme.spacing(10, 10, 10, 10),
        display: 'flex',
        justifyContent: 'center',

        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(10, 5, 5, 5),
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center'
        },
    },
    packPrice: {
        fontSize: 64,
        fontWeight: 700,

        '& span': {
            fontSize: 18,
            fontWeight: 400,
            color: theme.palette.grey[700]
        },

        [theme.breakpoints.down('md')]: {
            fontSize: 48,
        },
    },
    packName: {
        fontSize: 36,
        [theme.breakpoints.down('md')]: {
            fontSize: 32,
        },
    },
    packSubTitle: {
        color: theme.palette.grey[700]
    },
    pricingCard: {
        width: '20rem',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(4.25),
        borderRadius: 28,
        boxShadow: '0 4px 15px 0 #D3E9FF',
        alignItems: 'stretch',
        margin: theme.spacing(0, 3, 0, 3),

        '& ul': {
            paddingLeft: 0,
            marginTop: theme.spacing(4),

            '& li': {
                listStyle: 'none',
                marginBottom: theme.spacing(3),
                color: theme.palette.grey[700],
                '& svg': {
                    verticalAlign: 'middle',
                    marginRight: theme.spacing(1)
                }
            }
        },

        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2.5),
            margin: theme.spacing(0, 1.25, 0, 1.25),
        },

        [theme.breakpoints.down('sm')]: {
            minHeight: '25rem',
            marginBottom: '1.5rem',
            width: '18rem',
        },
    },
    recommendedCard: {
        background: 'linear-gradient(180deg, #5E6EF9 0%, #43BEE5 100%)',
        position: 'relative',

        '& li, & .MuiTypography-body1': {
            color: `${theme.palette.common.white} !important`,
            '& span': {
                color: 'rgba(255, 255, 255, 0.5)'
            },

            '& svg': {
                '& #tickBox, & #tick': {
                    fill: theme.palette.common.white
                }
            }
        }
    },
    recommendedStarArea: {
        position: 'relative',
        '& img': {
            position: 'absolute',
            right: 0,
            bottom: 0,
            transform: 'translate(85%, 15%)'
        },


        [theme.breakpoints.down('md')]: {
            '& img': {
                transform: 'translate(65%, 20%)'
            }
        },
    },
    whiteInvertedCurveArea: {
        position: 'relative',

        '& img': {
            width: '100%',
            position: 'absolute',
            top: -1,
        }
    },
    dotsArea: {
        position: 'relative',
        '& .topLeft': {
            position: 'absolute',
            left: 0
        },
        '& .bottomRight': {
            position: 'absolute',
            right: 0,
            zIndex: 99999,
            transform: 'translateY(-50%)',
        },

        [theme.breakpoints.down('md')]: {
            '& .topLeft': {
                display: 'none'
            },
            '& .bottomRight': {
                display: 'none'
            },
        },
    }
}))

const PricingSection = () => {
    const classes = useStyles();
    const [pricingDetails] = useState([
        {
            id: 'PA1',
            price: 0,
            packName: 'Intro',
            subTitle: 'Amet minim mollit non deserunt ullamco est sit aliqua.',
            features: [
                'deserunt ullamco est sit aliqua.',
                'deserunt ullamco est sit aliqua.'
            ],
            isRecommended: false
        },
        {
            id: 'PA2',
            price: 5,
            packName: 'Pro',
            subTitle: 'Amet minim mollit non deserunt ullamco est sit aliqua.',
            features: [
                'deserunt ullamco est sit aliqua.',
                'deserunt ullamco est sit aliqua.',
                'deserunt ullamco est sit aliqua.',
                'deserunt ullamco est sit aliqua.',
            ],
            isRecommended: true
        },
        {
            id: 'PA3',
            price: 10,
            packName: 'Ultimate',
            subTitle: 'Amet minim mollit non deserunt ullamco est sit aliqua.',
            features: [
                'deserunt ullamco est sit aliqua.',
                'deserunt ullamco est sit aliqua.',
                'deserunt ullamco est sit aliqua.',
                'deserunt ullamco est sit aliqua.',
                'deserunt ullamco est sit aliqua.',
                'deserunt ullamco est sit aliqua.',
            ],
            isRecommended: false
        }
    ])

    return (
        <section className={classes.root}>
            <div className={classes.dotsArea}>
                <img className='topLeft' src={HalfDots} alt='half dots' />
            </div>
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={classes.heroText} variant='h1'>Our Pricings</Typography>
                    <Typography className={classes.subText}>You choose the experience that best supports <br /> your unique journey. We'll do the rest.</Typography>
                </Grid>
            </Grid>
            <div className={classes.pricingCardList}>
                {
                    pricingDetails.map(eachPack => (
                        <div key={eachPack.id} className={clsx(classes.pricingCard, eachPack.isRecommended && classes.recommendedCard)}>
                            {
                                eachPack.isRecommended ? <div className={classes.recommendedStarArea}><img src={Star} alt='star icon' /></div> : null
                            }
                            <Typography className={classes.packPrice}>${eachPack.price}{
                                (eachPack.price > 0) ? <span>/month</span> : null
                            }</Typography>
                            <Typography className={classes.packName}>{eachPack.packName}</Typography>
                            {/* <Typography className={classes.packSubTitle}>{eachPack.subTitle}</Typography> */}
                            <ul>
                                {
                                    eachPack.features.map((eachFeature, index) => (
                                        <li key={`${eachPack.id}-${index}`}>
                                            <PricingTick />
                                            {eachFeature}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    ))
                }
            </div>

            <div className={classes.dotsArea}>
                <img className='bottomRight' src={HalfDots} alt='half dots' />
            </div>
            <div className={classes.whiteInvertedCurveArea}>
                <img src={WhiteInvertedCurve} alt='white inverted curve' />
            </div>
        </section>
    )
}

export default PricingSection
