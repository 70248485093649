import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import React from 'react';
import DashboardImage from '../../assets/dashboard_image.png';
import WhiteNormalCurve from '../../assets/white_normal_curve.svg';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(10, 0, 0, 10),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(10, 3, 0, 3),
        }
    },
    heroText: {
        fontWeight: 900,
        fontSize: 72,
        [theme.breakpoints.down('md')]: {
            fontSize: 64,
        }
    },
    subText: {
        color: theme.palette.grey[700],
        marginTop: theme.spacing(5),
        lineHeight: '200%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }
    },
    whiteCurveArea: {
        position: 'relative',
        '& img': {
            position: 'absolute',
            bottom: -1,
            zIndex: -1,
        }
    },
    dashboardImageArea: {
        textAlign: 'right',

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.down('md')]: {
            '& img': {
                width: '100%'
            }
        }
    },
    getStarted: {
        marginTop: theme.spacing(5),
        width: '12rem',
        padding: theme.spacing(1.5, 0, 1.5, 0),

        '& .MuiButton-label': {
            textTranform: 'capitalize'
        },
    }
}))


const HomeHeroSection = () => {
    const classes = useStyles();
    return (
        <section>
            <Grid container className={classes.root}>
                <Grid item xs={12} sm={12} md={4}>
                    <Typography className={classes.heroText} variant='h1'>Fast,<br /> Secure &<br /> Optimized</Typography>
                    <Typography className={classes.subText}>
                        Instaconnect is the highest quality video calling app. It’s free, simple and works on Android phones, iPhones, tablets, computers, and smart displays, like the Google Nest Hub Max.
                    </Typography>
                    <Button disableElevation className={classes.getStarted} variant='contained' color='primary' endIcon={<SendIcon />}>Get Started</Button>
                </Grid>
                <Grid item xs={12} sm={12} md={8} className={classes.dashboardImageArea}>
                    <img src={DashboardImage} alt='dashboard' />
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.whiteCurveArea}>
                <img width='100%' src={WhiteNormalCurve} alt='white curve' />
            </Grid>
        </section>
    )
}

export default HomeHeroSection
