import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import InfoIcon from '@material-ui/icons/Info';
import MailIcon from '@material-ui/icons/Mail';

export const linkType = {
    AUTH: 'AUTH',           // for login, signup pages
    PUBLIC: 'PUBLIC'        // for normal public pages
}


export const allNavbarLinks = [
    {
        label: 'Pricing',
        url: '#pricing',
        icon: <AttachMoneyIcon color='primary' />,
        linkType: linkType.PUBLIC
    },
    {
        label: 'About',
        url: '#about',
        icon: <InfoIcon color='primary' />,
        linkType: linkType.PUBLIC
    },
    {
        label: 'Contact Us',
        url: '#contactUs',
        icon: <MailIcon color='primary' />,
        linkType: linkType.PUBLIC
    },
    {
        label: 'Login',
        url: '/login',
        icon: null,
        linkType: linkType.AUTH
    },
]